<template>
  <div class="wrapper blue-circles">
    <AuthHeader />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import AuthHeader from "../../components/headers/Auth.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "ProfileMain",
  components: {
    AuthHeader,
    Footer,
  },
};
</script>